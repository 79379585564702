<script setup lang="ts">
defineProps<{
  config: {
    stacked: boolean
    logoPosition: 'left' | 'center'
    menuPosition: 'right' | 'center'
    backgroundClass: string
    containerClasses: string
    bookNowBackground: string
    languageSelectorText: string
    bookNowText: string
    booking: boolean
    noSpacer: boolean
    scrollTriggerDistance: number
    scrollClass: string
    textClass: string
    backdropBlur: boolean
  }
}>()

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { playaLayoutMounted, setupHeader } = usePlayaLayout()

const currentResort = inject<any>('currentResort')

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

if (!isVoixTemplatizer)
  setupHeader('f1c748e5-8ed2-46df-aee5-1ffc70f24022')
</script>

<template>
  <div>
    <div
      class="text-grey-darker bg-abs-white sanctuary-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />
        <div id="portal-mobile-menu" role="navigation" />

        <div id="playa-content">
          <NavigationHeader
            v-if="!isVoixTemplatizer"
            :config="config"
          >
            <template #logo>
              <img v-if="currentResort.logo" :src="currentResort.logo" :alt="`${currentResort.name} Logo`" class="w-32">
              <LogosWyndham v-else class="w-40 text-white" />
            </template>
            <template #mobilelogo>
              <img v-if="currentResort.logo" :src="currentResort.logo" :alt="`${currentResort.name} Logo`" class="invert w-32">
              <LogosWyndham v-else class="w-40 text-gray-600" />
            </template>
          </NavigationHeader>
          <div class="min-h-[300px]">
            <slot />
          </div>

          <NavigationFooter
            v-if="!isVoixTemplatizer"
            :config="{
              signUpForm: true,
              termsMenuPosition: 'left', // left or center
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
